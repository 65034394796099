import React from "react";
import propTypes from "prop-types";
import { useDefaultsData } from "@staticQueries";
import { Helmet } from "react-helmet";
import ImgixClient from "@imgix/js-core";

const Meta = ({
  title: _title,
  metaTitle: _metaTitle,
  metaDescription,
  metaImage,
  url,
  slug,
}) => {
  if (typeof window === "undefined") {
    let errors = "";
    if (!_title) errors += "title ";
    if (metaDescription) errors += "metaDescription ";
    // if (_metaImage) errors += "_metaImage ";
    if (url) errors += "url";
    if (errors?.length) {
      // eslint-disable-next-line no-console
      console.log("\x1b[31m", `${slug} missing meta: ${errors}`);
    }
  }

  const metaTitle = _metaTitle;
  // const metaImage = metaImage

  return (
    <Helmet>
      <title>{metaTitle}</title>
      {/* <!-- Short description of the document (limit to 150 characters) --> */}
      {/* <!-- This content *may* be used as a part of search engine results. --> */}
      <meta name="description" content={metaDescription} />

      {/* <!-- Control the behavior of search engine crawling and indexing --> */}
      {/* <!-- All Search Engines --> */}
      <meta name="robots" content="index,follow" />
      {/* <!-- Google Specific --> */}
      <meta name="googlebot" content="index,follow" />

      {/* facebook og */}
      <meta property="og:title" name="og:title" content={metaTitle} />
      {metaImage && (
        <meta property="og:image" name="og:image" content={metaImage} />
      )}
      <meta
        property="og:description"
        name="og:description"
        content={metaDescription}
      />
      <meta property="og:type" name="og:type" content="website" />
      <meta
        property="og:url"
        name="og:url"
        content={`${url}${url?.slice(-1) !== "/" ? "" : "/"}`}
      />
      <meta
        property="fb:app_id"
        name="fb:app_id"
        content={process.env.GATSBY_FACEBOOK_APP_ID}
      />

      {/* twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      {metaImage && <meta name="twitter:image" content={metaImage} />}
    </Helmet>
  );
};

Meta.defaultProps = {};

Meta.propTypes = {};

export default Meta;
