import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import ImgixClient from "@imgix/js-core";
import { InView } from "@base";
import { useWindowSize } from "@utils";
// import gsap from "gsap";
import classNames from "classnames";

const Image = ({
  url,
  caption,
  fit,
  alt,
  aspectratio,
  position,
  delay,
  placeholder,
  fill,
  params,
  height: _height,
  width: _width,
  focalPoint,
  round,
  fitHeight,
  className,
  loadedState,
  urlOverride,
  name,
  className: _className,
}) => {
  if (name) {
    const sizes = [400, 800, 1200, 1600, 2000];
    const imgUrl = `https://lisabenson.imgix.net/${name}?auto=format,compress`;
    const srcSet = sizes
      .map(size => `${imgUrl}&w=${size}&h=${size}&fit=max ${size}w`)
      .join(", ");
    return (
      <figure
        className={classNames("z-0 absolute inset-0 h-full w-full", _className)}
      >
        <img
          className={classNames(
            "absolute inset-0 object-bottom h-full w-full",
            {
              "object-contain": fit === "contain",
              "object-cover": fit !== "contain" || fill,
            }
          )}
          srcSet={srcSet}
          src={`${imgUrl}&w=400&h=400`}
          alt={alt}
        />
      </figure>
    );
  }
  if (url) {
    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = loadedState || useState(false);
    const [width, setWidth] = useState(100);
    const [height, setHeight] = useState(100);
    const [clientWidth, setClientWidth] = useState(0);
    const { innerWidth: windowSize } = useWindowSize();
    const imgContainer = useRef();
    const imgRef = useRef();
    const imgPlaceholder = useRef();

    // get pixel device ratio, set to 1 if window or pdr is undefined
    const pr = typeof window !== "undefined" ? window.devicePixelRatio || 1 : 1;

    // calculate aspect ratio
    let arm;
    if (aspectratio != null) {
      const arSplit = aspectratio.includes("/")
        ? aspectratio.split("/")
        : aspectratio.split(":");
      arm = parseInt(arSplit[1], 10) / parseInt(arSplit[0], 10);
    }

    const domain = process.env.GATSBY_IMGIX_SUBDOMAIN
      ? `${process.env.GATSBY_IMGIX_SUBDOMAIN}.imgix.net`
      : null;

    const processedUrl = encodeURI(
      url.replace(`${process.env.GATSBY_IMGIX_BASE_URL}`, "/")
    );

    const ixConfig = { domain };

    // setup client if there is a domain in the config
    let client;
    const uix = ixConfig.domain;
    if (uix) {
      client = new ImgixClient(ixConfig);
    }

    // configure default params
    const defaultParams = {
      auto: "compress,format",
      fit: arm || fill ? "crop" : "max",
      crop: "faces,edges",
      q: 50,
    };

    // merge with params from prop
    let ixParams = {
      ...defaultParams,
      ...params,
    };

    // if focal point is set, overwrite crop settings
    if (focalPoint) {
      ixParams = {
        ...ixParams,
        fit: "crop",
        crop: "focalpoint",
        "fp-x": focalPoint[0],
        "fp-y": focalPoint[1],
      };
    }

    // load placeholder image – helps browser determine image size before it loads
    const lqipSrc = uix
      ? client.buildURL(processedUrl, {
          ...defaultParams,
          w: 200,
          h: arm ? 200 * arm : "auto",
          blend: "#fff",
          "blend-mode": "normal",
          q: 0,
          // h: 300,
        })
      : url;

    const getHeight = w => {
      if (arm) {
        return w * arm;
      }
      if (fill) {
        return imgContainer.current.clientHeight;
      }
      return w * (_height / _width);
    };

    // change the height and width on page resize
    useEffect(() => {
      const w = imgContainer.current.clientWidth;
      setClientWidth(w);
      setWidth(
        Math.ceil((imgContainer.current.clientWidth + 1) / 10).toFixed(0) * 10
      );
      setHeight(getHeight(w));
    }, [windowSize, loaded, getHeight]);

    // fade in the image and fade out the placeholder onLoad
    useEffect(() => {
      if (loaded && visible) {
        setTimeout(() => {
          // gsap.fromTo(
          //   imgRef.current,
          //   {
          //     // scale: 1.025,
          //     // filter: "blur(8px)",
          //     opacity: 0,
          //   },
          //   {
          //     display: "block",
          //     opacity: 1,
          //     // scale: 1,
          //     duration: 0.3,
          //     ease: "power1.in",
          //     // filter: "blur(0px)",
          //     clearProps: "transform",
          //     onComplete: () => {
          //       if (imgRef.current) {
          //         imgRef.current.style.transform = null;
          //       }
          //     },
          //   }
          // );
          // gsap.fromTo(
          //   imgPlaceholder.current,
          //   { opacity: 1 },
          //   {
          //     opacity: 0,
          //     duration: 0.3,
          //     ease: "power1.in",
          //     delay: 0.5,
          //   }
          // );
        }, 0);
      }
    }, [delay, loaded, visible]);

    // get the final url
    const src = uix
      ? client.buildURL(processedUrl, {
          ...ixParams,
          w: width.toFixed(0),
          h: (arm ? width * arm : height).toFixed(0),
          dpr: pr > 2 ? 2 : pr,
          q: pr > 1 ? 10 : 50,
        })
      : url;

    // return image
    return (
      <InView
        observerOptions={{
          threshold: 0.01,
        }}
        className={`w-full ${arm ? "h-auto" : "h-full"}`}
        onEnter={() => {
          setVisible(true);
        }}
        unobserveAfterEntry
      >
        <figure
          ref={imgContainer}
          className={`w-full ${arm ? "h-auto" : "h-full"} transition relative
          ${caption ? "" : "overflow-hidden"}
          ${round ? "rounded-full overflow-hidden" : ""}`}
          style={
            !fill && !fitHeight && clientWidth
              ? { height: getHeight(clientWidth) || 0 }
              : null
          }
        >
          {/* the actual image */}
          <img
            ref={imgRef}
            className={`z-20 inset-0 opacity-0 hidden transition duration-700 ease
              object-${fill ? "cover" : fit} 
              object-${position} 
              ${fill ? "absolute" : "relative"}
              ${arm ? "h-auto" : "h-full"} w-full transition`}
            src={visible ? src : null}
            alt={
              alt ||
              url.split("/")[url.split("/").length - 1].split("?")[0] ||
              "image"
            }
            onLoad={() => {
              setLoaded(true);
            }}
            style={{ willChange: "transform" }}
          />
          {/* placeholder before image load-in */}
          {placeholder && (
            <div
              ref={imgPlaceholder}
              className={` bg-black flex items-center justify-center z-10   
            absolute inset-0
            object-${fill ? "cover" : fit} 
            object-${position} 
            ${arm ? "h-auto" : "h-full"} 
            w-full transition`}
            />
          )}
          {/* no js fallback */}
          <noscript>
            <img
              className={`absolute inset-0 blur-in object-${fit} h-full w-full transition`}
              src={src}
              alt={alt || "image"}
            />
          </noscript>
          {/* load in a caption below just if provided */}
          {caption && (
            <figcaption
              className="absolute bottom-0 left-0 text-blue transform translate-y-full text-xs font-mono pt-3"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: caption }}
            />
          )}
        </figure>
      </InView>
    );
  }
  return null;
};

Image.defaultProps = {
  url: null,
  caption: null,
  fit: "contain",
  alt: null,
  aspectratio: null,
  position: "center",
  fill: false,
  delay: 0,
  params: null,
  placeholder: true,
  fitHeight: false,
};

Image.propTypes = {
  url: propTypes.string,
  caption: propTypes.string,
  fit: propTypes.string,
  placeholder: propTypes.bool,
  alt: propTypes.string,
  aspectratio: propTypes.string,
  position: propTypes.string,
  fill: propTypes.bool,
  delay: propTypes.number,
  params: propTypes.objectOf(
    propTypes.oneOfType([propTypes.string, propTypes.number])
  ),
  focalPoint: propTypes.oneOfType([propTypes.bool, propTypes.array]).isRequired,
  fitHeight: propTypes.bool,
};

export default Image;
