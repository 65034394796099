import React from "react";
import propTypes from "prop-types";
import { Icon, AppLink } from "@base";

const ButtonAlt = ({
  to,
  children,
  className: _className,
  reverse,
  icon,
  onClick,
}) => {
  return (
    <AppLink
      to={to}
      onClick={onClick}
      className={`inline-flex items-center ${_className}`}
    >
      {/* back arrow */}
      {icon && reverse && (
        <span
          className={`icon h-4 w-4 ${
            icon ? "" : "transform rotate-180"
          } flex-shink-0 mr-1`}
        >
          <Icon name={icon || "chevron"} />
        </span>
      )}
      {/* label */}
      <span className="underline-red text-xs font-bold uppercase leading-normal">
        {children}
      </span>
      {/* forward arrow */}
      {icon && !reverse && (
        <span className="icon h-4 w-4 flex-shink-0 ml-1">
          <Icon name={icon || "chevron"} />
        </span>
      )}
    </AppLink>
  );
};

ButtonAlt.defaultProps = {
  reverse: false,
  icon: false,
};

ButtonAlt.propTypes = {};

export default ButtonAlt;
