import React from "react";
import propTypes from "prop-types";
import { AppLink } from "@base";
import { useAppState } from "@state";
import classNames from "classnames";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
}) => {
  const [{ ec }, dispatch] = useAppState();

  const defaultClassName = `inline-flex justify-center items-center cursor-pointer items-center relative z-0 uppercase font-bold group leading-tighter transition duration-200`;

  const sizes = {
    fluid: "p-2 text-xs",
    xxs: "p-2 text-xs",
    xs: "px-5 py-2 text-xxs",
    md: "px-4 py-3 text-xs",
    lg: "px-10 py-5 text-base",
    sm: "py-3 px-6 text-xs",
  };

  const colors = {
    red: "bg-red hover:bg-red-dark text-white",
  };

  const classes = classNames(
    defaultClassName,
    sizes[size] || "py-4 px-10 text-sm",
    colors[color] || color,
    {
      "rounded-full": rounded,
      "w-full": fullWidth,
    }
  );

  if (to && to.length > 1) {
    return (
      <LinkObj to={to} title={title} className={classes} onClick={onClick}>
        <span className="flex relative z-10 text-center">{children}</span>
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classes}
      onClick={onClick}
    >
      <span className="flex relative z-10 text-center">{children}</span>
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "red",
  title: null,
  type: "button",
  onClick: null,
  size: "default",
  to: null,
};

Button.propTypes = {
  linkObj: propTypes.func,
  color: propTypes.string,
  to: propTypes.string,
  title: propTypes.string,
  type: propTypes.string,
  onClick: propTypes.func,
  size: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default Button;
