import React, { useRef, useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import classNames from "classnames";
import { useWindowSize } from "@utils";
import { Icon } from "@base";

const Carousel = ({ children }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const slides = React.Children.toArray(children);
  const slideCount = slides.length;
  const carouselControls = useAnimation();
  const { innerWidth: windowWidth } = useWindowSize();
  const carouselContainer = useRef();

  const handleDrag = (event, info) => {
    const { x } = info.offset;
    requestAnimationFrame(() => {
      if (x < -slideWidth / 3) {
        setCurrentSlide(prevState => {
          if (prevState < slides.length - 1) {
            return prevState + 1;
          }
          carouselControls.start({
            x: `-${(currentSlide / slideCount) * 100}%`,
          });
          return prevState;
        });
      } else if (x > slideWidth / 3) {
        setCurrentSlide(prevState => {
          if (prevState > 0) {
            return prevState - 1;
          }
          carouselControls.start({
            x: `-${(currentSlide / slideCount) * 100}%`,
          });
          return prevState;
        });
      } else {
        carouselControls.start({
          x: `-${(currentSlide / slideCount) * 100}%`,
        });
      }
    });
  };

  useEffect(() => {
    carouselControls.start({
      x: `-${(currentSlide / slideCount) * 100}%`,
    });
  }, [currentSlide]);

  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        setSlideWidth(carouselContainer.current.clientWidth);
      });
    }
  }, [carouselContainer, windowWidth]);

  return (
    <>
      <div ref={carouselContainer} className="relative w-full">
        <button
          className={classNames(
            "absolute -left-[2rem] top-0 bottom-0 items-center justify-center z-10 hidden md:flex transition duration-200",
            {
              "opacity-0": currentSlide <= 0,
            }
          )}
          type="button"
          onClick={() => {
            setCurrentSlide(prevState => {
              if (prevState > 0) {
                return prevState - 1;
              }
              return prevState;
            });
          }}
        >
          <Icon name="chevron_left_1" />
        </button>
        <motion.div
          animate={carouselControls}
          className="flex"
          transition={{ duration: 0.2, type: "tween" }}
          style={{ width: `${slideCount * 100}%` }}
          drag="x"
          onDragEnd={handleDrag}
          dragConstraints={{ left: "-100%", right: 0 }}
        >
          {slides.map((slide, i) => (
            <div
              className={classNames("relative", {
                "opacity-25": currentSlide !== i,
              })}
              style={{ width: `${(1 / slideCount) * 100}%` }}
            >
              {slide}
            </div>
          ))}
        </motion.div>
      </div>
      {/* next button */}
      <button
        className={classNames(
          "absolute -right-[2rem] top-0 bottom-0 items-center justify-center z-10 hidden md:flex transition duration-200",
          {
            "opacity-0": currentSlide >= slideCount - 1,
          }
        )}
        type="button"
        onClick={() => {
          setCurrentSlide(prevState => {
            if (prevState < slides.length - 1) {
              return prevState + 1;
            }
            return prevState;
          });
        }}
      >
        <Icon name="chevron_right_1" />
      </button>
      {/* indicators */}
      <ul className="flex items-center justify-center">
        {slides.map((slide, i) => (
          <li>
            <button
              type="button"
              onClick={() => setCurrentSlide(i)}
              className={classNames(
                "bg-white h-2 w-2 rounded-full block mx-1 transition duration-200",
                {
                  "opacity-50": currentSlide !== i,
                }
              )}
            >
              <span className="hidden">{i}</span>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Carousel;
