import React, { useState, useRef, useEffect } from "react";
import propTypes from "prop-types";
import { chevron as Arrow } from "@svg";

const Dropdown = ({
  model,
  options,
  placeholder,
  inverse,
  size,
  blue,
  sans,
}) => {
  if (model) {
    const [isActive, setIsActive] = useState(false);
    const [value, setValue] = model;
    const dropdown = useRef();
    const tl = useRef();
    const arrow = useRef();

    const handleClickOutside = event => {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    useEffect(() => {
      setIsActive(false);
    }, [value]);

    useEffect(() => {
      // tl.current = gsap
      //   .timeline()
      //   .fromTo(
      //     dropdown.current,
      //     { height: 0 },
      //     { height: "auto", duration: 0.25, ease: "power1.in" }
      //   )
      //   .fromTo(
      //     arrow.current,
      //     { rotate: 0, y: 0 },
      //     { rotate: 90, y: -2, duration: 0.25, ease: "power1.in" },
      //     "=-0.25"
      //   );
      // document.addEventListener("mousedown", handleClickOutside);
      // return () => {
      //   tl.current.kill();
      //   document.removeEventListener("mousedown", handleClickOutside);
      // };
    }, []);

    useEffect(() => {
      if (tl.current) {
        if (isActive) {
          tl.current.play();
        } else {
          tl.current.reverse();
        }
      }
    }, [isActive]);

    // eslint-disable-next-line global-require

    let iconSize;

    switch (size) {
      case "xs":
        iconSize = "1";
        break;
      case "sm":
        iconSize = "2";
        break;
      case "md":
        iconSize = "3";
        break;
      case "lg":
        iconSize = "4";
        break;
      default:
        iconSize = "3";
        break;
    }

    let bgColor = "white";
    let textColor = "black";
    let dropDownColor = "red";

    if (inverse) {
      bgColor = "black";
      textColor = "white";
      dropDownColor = "red";
    }

    return (
      <div
        className={`relative ${
          sans ? "uppercase font-bold text-xs" : "font-serif"
        }`}
      >
        <button
          type="button"
          onClick={() => setIsActive(!isActive)}
          className={`flex items-center text-${size} bg-${bgColor} text-${textColor} justify-between ${
            size === "xs" ? "py-1 px-3" : "px-4 py-2"
          } w-full ${sans ? "" : "border"} 
          ${inverse ? "border-red" : "border-grey-light"}`}
        >
          <span className="text-left whitespace-nowrap mr-2">
            {value != null && value !== "" ? options[value] : placeholder}
          </span>
          {/* dropdown Icon */}
          <span
            ref={arrow}
            className={`icon w-${iconSize} block h-${iconSize} pl-1 text-${dropDownColor}`}
          >
            <Arrow />
          </span>
        </button>
        <div className="-mt-px relative">
          <ul
            ref={dropdown}
            className={`absolute z-40 left-0 text-${size} bg-${bgColor} text-${textColor}
           ${inverse ? "border-l border-r border-b border-red" : ""}
           right-0 h-0 overflow-hidden
           ${isActive ? "shadow-xl" : "shadow-none"}`}
          >
            {placeholder.length > 1 && (
              <li>
                <button
                  type="button"
                  className={`w-full px-4 py-2 text-${size} opacity-50 text-left`}
                  disabled
                >
                  {placeholder}
                </button>
              </li>
            )}
            {options.map((option, i) => {
              return (
                <li key={option.uid ? option.uid : option + String(i)}>
                  <button
                    onClick={() => setValue(i)}
                    type="button"
                    className="w-full px-4 py-2 text-left hover:bg-red transition duration-200"
                  >
                    {option}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
  return null;
};

Dropdown.defaultProps = {
  placeholder: "Select One",
  inverse: false,
  size: "md",
  blue: false,
};

Dropdown.propTypes = {
  model: propTypes.arrayOf(
    propTypes.oneOfType([propTypes.string, propTypes.func])
  ).isRequired,
  options: propTypes.arrayOf(propTypes.string).isRequired,
  placeholder: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  inverse: propTypes.bool,
  size: propTypes.string,
  blue: propTypes.bool,
};

export default Dropdown;
